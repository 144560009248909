export const ADJUST_RECENT_DAY_FACTOR = 1.5;
export const CANDLE_HEIGHT = 80;
export const CANDLE_HEIGHT_PAD = 80;
export const CANDLE_HEIGHT_PHONE = 64;
export const LINE_CHART_HEIGHT = 80;
export const LINE_CHART_HEIGHT_PAD = 80;
export const LINE_CHART_HEIGHT_PHONE = 60;
export const LINE_CHART_WIDTH_PHONE = 60;
export const LINE_CHART_WIDTH_PAD = 120;
export const LINE_CHART_WIDTH = 120;
export const SHORT_MA_DAY = 20;
export const LONG_MA_DAY = 60;
