import React, { memo } from 'react';
import dayAPI from '~/utils/dayAPI';
import { toPercentage } from '~/utils/toPercentage';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import first from 'lodash/first';
import last from 'lodash/last';
import { useGetPrice } from './useGetPrice';
const textCss = css `
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  ${flex.h.allCenter};
`;
const priceBackgroundColor = (changeValue) => {
    if (changeValue > 8)
        return '#C62828';
    else if (changeValue > 6)
        return '#E53935';
    else if (changeValue > 4)
        return '#F44336';
    else if (changeValue > 2)
        return '#EF5350';
    else if (changeValue > 0)
        return '#E57373';
    else if (changeValue === 0)
        return '#BDBDBD';
    else if (changeValue > -2)
        return '#81C784';
    else if (changeValue > -4)
        return '#66BB6A';
    else if (changeValue > -6)
        return '#4CAF50';
    else if (changeValue > -8)
        return '#43A047';
    else
        return '#2E7D32';
};
const priceStringColor = (changeValue) => (changeValue === 0 ? '#111111' : '#ffffff');
/** 時間內的變化 一日內：兩根K棒 五日內：五根K棒 */
export const PriceChange = memo(function PriceChange(props) {
    const endOnSpecDate = props.endOnSpecDate || dayAPI();
    const recentDays = props.recentDays || 365;
    const count = props.count;
    const data = useGetPrice(endOnSpecDate, recentDays, count, props.symbolNumber);
    if (!data || (data && data.length === 0))
        return (<div css={css `
          width: 100%;
          height: 100%;
          ${flex.h.allCenter};
          font-size: 8px;
        `}>
        Loading
      </div>);
    const change = toPercentage(last(data)?.close ?? 0, first(data)?.close ?? 0, true);
    return (<div css={css `
        width: 100%;
        height: 100%;
        background: ${priceBackgroundColor(change)};
        color: ${priceStringColor(change)};
        ${textCss}
      `}>
      {change}%
    </div>);
});
