import { useGetPrice } from './useGetPrice';
import { toPercentage } from '~/utils/toPercentage';
import { first, last } from 'lodash';
/** 檢查4個不同天數的價格是否都`大於/小於0`或是`有上升/下降趨勢` */
export const useCheckPriceState = (endOnSpecDate, symbolNumber, 
/** 擷取4個時間點的價格 */
countArray, 
/**
 * LongTrend:`多頭`
 * ShortTrend:`空頭`
 * Positive:`時間節點漲幅皆大於0`
 * Negative:`時間節點漲幅皆小於0`
 * */
mode, recentDays) => {
    const price1 = useGetPrice(endOnSpecDate, recentDays ?? 365, countArray[0], symbolNumber);
    const price2 = useGetPrice(endOnSpecDate, recentDays ?? 365, countArray[1], symbolNumber);
    const price3 = useGetPrice(endOnSpecDate, recentDays ?? 365, countArray[2], symbolNumber);
    const price4 = useGetPrice(endOnSpecDate, recentDays ?? 365, countArray[3], symbolNumber);
    const change1 = toPercentage(last(price1)?.close ?? 0, first(price1)?.close ?? 0, true);
    const change2 = toPercentage(last(price2)?.close ?? 0, first(price2)?.close ?? 0, true);
    const change3 = toPercentage(last(price3)?.close ?? 0, first(price3)?.close ?? 0, true);
    const change4 = toPercentage(last(price4)?.close ?? 0, first(price4)?.close ?? 0, true);
    const longTrend = change4 > change3 && change3 > change2 && change2 > change1;
    const shortTrend = change4 < change3 && change3 < change2 && change2 < change1;
    const positive = change4 > 0 && change3 > 0 && change2 > 0 && change1 > 0;
    const Negative = change4 < 0 && change3 < 0 && change2 < 0 && change1 < 0;
    if (mode === 'Positive')
        return positive;
    if (mode === 'Negative')
        return Negative;
    if (mode === 'LongTrend')
        return longTrend;
    if (mode === 'ShortTrend')
        return shortTrend;
    if (mode === 'NoCheck')
        return true;
};
