import { min } from 'lodash';
/**
 * 算出大戶掛單、大戶力道的指針角度
 *
 * - 此計算會得出 `-1` 至 `1`
 * - 此演算邏輯屬前端指針介面的角度，因此邏輯演算留於前端
 */
export const getIndicatorAngular = (dValue) => {
    const displayValue = Math.sign(dValue) * (min([Math.sqrt(Math.abs(dValue)) / 100, 1]) ?? 1);
    return displayValue;
};
